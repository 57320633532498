import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useSiteStore from "../store/useSiteStore";
import { useEffect, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";

export const ButtonNewPage = () => {
  const {
    site: { id: siteID },
  } = useSiteStore();
  const [templates, setTempaltes] = useState([]);

  const navigate = useNavigate();

  const fetchTemplates = () => {
    FetchWithAuth(
      "content/list/template?parent=" +
        process.env.REACT_APP_PAGE_TEMPLATE_PARENT +
        "&sortby=title&limit=-1"
    ).then((resp: any) => {
      if (resp.error === false) {
        setTempaltes(resp.data.list);
      }
    });
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle size="sm">
        <i className="bi bi-plus"></i>Opprette side
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="empty"
          onClick={() => navigate("/fullcreate/page/" + siteID)}
        >
          Tom
        </Dropdown.Item>
        <Dropdown.Divider />
        {templates.map((item: any, index) => (
          <Dropdown.Item
            eventKey={index}
            onClick={() =>
              navigate("/fullcreate/page/" + siteID + "?template=" + item.id)
            }
          >
            {item.metadata.name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          eventKey="link"
          onClick={() => navigate("/website/create/page/" + siteID)}
        >
          Lenke
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
