export const pageSettings = [
  { identifier: "cover_image", name: "Cover image", type: "image" },
  { identifier: "summary", name: "Summary", type: "richtext" },
  { identifier: "meta_key", name: "Meta key", type: "text" },
  {
    identifier: "meta_description",
    name: "Meta description",
    type: "multitext",
  },
];
