import { Button } from "react-bootstrap";
import util from "digimaker-ui/util";
import { useNavigate } from "react-router";
import { FetchWithAuth } from "digimaker-ui/util";
import useSiteStore from "../store/useSiteStore";
import Cookies from "universal-cookie";

export const Logout = () => {
  const nagivate = useNavigate();
  const { clearState } = useSiteStore();

  const logout = () => {
    FetchWithAuth("auth/token/revoke?token=" + util.getRefreshToken()).then(
      (data) => {
        fetch("https://klubb.bridge.no/user/logout", {
          redirect: "manual",
          credentials: "include",
        }).then((res) => {
          const cookie = new Cookies();
          cookie.remove("refreshToken", {
            path: "/",
            expires: new Date("1970-01-01 00:00:01"),
          });
          clearState();

          nagivate("/login");
        });
      }
    );
  };

  return (
    <Button size="sm" variant="danger" onClick={logout}>
      <i className="bi bi-box-arrow-right"></i> Logout{" "}
    </Button>
  );
};
