import { css } from "@emotion/css";
import PreviewIcon from "@mui/icons-material/Preview";
import util from "digimaker-ui/util";
import { useState } from "react";
import { Modal } from "react-bootstrap";

export const ImagePreview = (props: { content }) => {
  const { content } = props;

  const [shown, setShown] = useState(false);
  const handle = (e) => {
    setShown(true);
  };

  const thumbnailUrl = util.washVariables(
    process.env.REACT_APP_THUMB_PATH || "",
    {
      ...util._vars,
      imagepath: content.image,
    }
  );

  const imageUrl = util.washVariables(process.env.REACT_APP_ASSET_URL || "", {
    ...util._vars,
    imagepath: content.image,
  });

  return (
    <div>
      <div
        className={css`
          cursor: pointer;
          margin-top: 5px;

          :hover {
            opacity: 0.8;
          }

          img {
            height: 80px;
            object-fit: contain;
          }
        `}
        onClick={handle}
      >
        <img src={thumbnailUrl} />
      </div>
      <Modal
        size="lg"
        show={shown}
        onHide={() => {
          setShown(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{content.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img src={imageUrl} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
